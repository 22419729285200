<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-form>
      <b-row>
        <!-- Name Building -->
        <b-col cols="4">
          <b-form-group label-for="type">
            <template v-slot:label>
              Hình thức mượn <span class="text-danger">*</span>
            </template>
            <v-select
              v-model="dataForm.type"
              :options="listType"
              placeholder="[Chọn]"
              :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label-for="input_start_date">
            <template v-slot:label>
              Ngày yêu cầu <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên tòa nhà"
              rules="required"
            >
              <flat-pickr
                v-model="dataForm.input_start_date"
                class="form-control"
                :config="config"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label-for="status"
          >
            <template v-slot:label>
              Số lượng <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="dataForm.document_quantity"
              placeholder="Số lượng"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="float-left">
            <b-button
              variant="primary"
              class="mr-1"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BButton, BCol, BOverlay, BRow, VBTooltip, BFormGroup, BForm, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  name: 'FormPhieuMuon',
  components: {
    BButton,
    BCol,
    BOverlay,
    BRow,
    vSelect,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataForm: {},
  },
  data() {
    return {
      isLoading: false,
      total: 0,
      listType: [
        { value: 0, label: 'Mượn về nhà' },
        { value: 1, label: 'Mượn đọc tại chỗ' },
      ],
      config: {
        enableTime: false,
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: true,
      },
    }
  },
  computed: {},
  watch: {
    // listening change data init
  },
  async created() {
    // init data FROM API
    this.dataForm.input_start_date = new Date()
  },
  methods: {
    ...mapActions({
      registerBorrowing: 'digitalLibrary/registerBorrowing',
    }),
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    async handleSaveData() {
      this.isLoading = true
      const data = {}
      data.type = this.dataForm.type
      data.input_start_date = this.formatDate(this.dataForm.input_start_date)
      data.input_end_date = this.formatDate(this.dataForm.input_start_date)
      const document = []
      document.push({
        id: this.dataForm.id,
        name: this.dataForm.name,
        document_quantity: this.dataForm.document_quantity,
      })
      data.document = document
      const response = await this.dangKyPhieuMuon(data)
      if (response) {
        this.showToast('Tạo phiếu thành công!', 'CheckIcon', 'success')
      } else {
        this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'warning')
      }
      this.isLoading = false
    },
    formatDate(date) {
      const inputEndDate = new Date(date)
      const day = inputEndDate.getDate().toString().padStart(2, '0')
      const month = (inputEndDate.getMonth() + 1).toString().padStart(2, '0')
      const year = inputEndDate.getFullYear()
      return `${day}/${month}/${year}`
    },
  },
}
</script>

<style scoped>

</style>
